<template>
  <v-app id="app">
    <pages-core-app-bar />
    <pages-core-view />

    <progress-bar-circular />
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",
  components: {
    ProgressBarCircular: () => import("@/components/ui/ProgressBarCircular"),
    PagesCoreAppBar: () => import("./components/core/AppBar"),
    PagesCoreView: () => import("./components/core/ViewSignUp"),
  },

  created() {
    this.$vuetify.theme.dark = true;
  },

  beforeDestroy() {
    this.$vuetify.theme.dark = true;
  },
};
</script>
<style scoped lang="sass">
</style>
